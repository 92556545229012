import { __decorate } from "tslib";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
import EventModule from "@/store/module/shared/EventModule";
import UserModule from "@/store/module/user/UserModule";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import Blocks from "@/components/shared/Blocks.vue";
import EventRegionalStatusTabs from "@/components/user/events/EventRegionalStatusTabs.vue";
import EventsHeaderActions from "@/components/user/events/EventsHeaderActions.vue";
let EventsPage = class EventsPage extends Vue {
    constructor() {
        super(...arguments);
        this.activeRegionalStatus = 1;
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get events() {
        return this.$store.getters[EventModule.types.getters.EVENTS];
    }
    get allowedEvents() {
        return this.events
            .filter((e) => e.type.regionalStatusId === this.activeRegionalStatus && !e.isPremoderated)
            .sort((a, b) => moment(b.endDate).toDate().getTime() - moment(a.endDate).toDate().getTime());
    }
    get eventsBlocks() {
        const items = [];
        for (let event of this.allowedEvents) {
            const endDiff = moment().diff(moment(event.endDate), "seconds");
            const startDiff = moment().diff(moment(event.startDate), "seconds");
            const regEndDiff = moment().diff(moment(event.registrationEndDate));
            let status = "";
            if (regEndDiff < 0) {
                status = "регистрация открыта";
            }
            else if (regEndDiff > 0 && startDiff <= 0) {
                status = `регистрация закрыта`;
            }
            else if (startDiff > 0 && endDiff < 0) {
                status = `проходит сейчас`;
            }
            else {
                status = `завершено`;
            }
            items.push({
                image: `${IMAGE_SERVER_URL}/public/${event.logo}`,
                href: `/user/events/${event.id}`,
                title: event.name,
                list: [
                    `Начало: ${moment(event.startDate).format("YYYY-MM-DD")}`,
                    `Завершение: ${moment(event.endDate).format("YYYY-MM-DD")}`,
                    `Статус:&nbsp;<b>${status}</b>`,
                ],
            });
        }
        return items;
    }
    setRegionalStatus(regionalStatus) {
        this.activeRegionalStatus = regionalStatus;
    }
    async mounted() {
        if (this.events.length === 0) {
            await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
            await this.$store.dispatch(EventModule.types.actions.GET_EVENTS, { regionId: this.user.address.regionId });
        }
    }
};
EventsPage = __decorate([
    Component({
        components: {
            Blocks,
            EventRegionalStatusTabs,
            EventsHeaderActions
        },
    })
], EventsPage);
export default EventsPage;
