import { __decorate } from "tslib";
import EventModule from "@/store/module/shared/EventModule";
import HandbookModule from "@/store/module/shared/HandbookModule";
import Vue from "vue";
import Component from "vue-class-component";
let EventRegionalStatusTabs = class EventRegionalStatusTabs extends Vue {
    constructor() {
        super(...arguments);
        this.activeTab = 0;
    }
    get eventRegionalStatuses() {
        return this.$store.getters[HandbookModule.types.getters.EVENT_REGIONAL_STATUSES];
    }
    get events() {
        return this.$store.getters[EventModule.types.getters.EVENTS];
    }
    getEventsCountByRegionalStatus(regionanlStatusId) {
        return this.events.filter((item) => item.type.regionalStatusId === regionanlStatusId && item.isPremoderated === false).length;
    }
    getPremoderatedEventsCount() {
        return this.events.filter((e) => e.isPremoderated).length;
    }
};
EventRegionalStatusTabs = __decorate([
    Component
], EventRegionalStatusTabs);
export default EventRegionalStatusTabs;
