import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
let Blocks = class Blocks extends Vue {
    get itemsView() {
        if (this.$vuetify.breakpoint.xsOnly) {
            return this.createItemsView(1);
        }
        else if (this.$vuetify.breakpoint.smAndDown) {
            return this.createItemsView(2);
        }
        else if (this.$vuetify.breakpoint.mdAndDown) {
            return this.createItemsView(3);
        }
        else {
            return this.createItemsView(4);
        }
    }
    createItemsView(cols = 3) {
        let uniqId = 0;
        const itemsView = {
            colSize: 12 / cols,
            rows: [],
        };
        for (let i = 0; i < this.itemsRaw.length; i++) {
            if (i % cols === 0) {
                itemsView.rows.push({
                    id: uniqId,
                    cols: [],
                });
                uniqId++;
            }
            itemsView.rows[itemsView.rows.length - 1].cols.push({
                ...this.itemsRaw[i],
                id: uniqId,
            });
            uniqId++;
        }
        return itemsView;
    }
    goto(path) {
        this.$router.push(path);
    }
};
__decorate([
    Prop({ type: Number, default: 150 })
], Blocks.prototype, "itemSize", void 0);
__decorate([
    Prop({ type: String, default: "100%" })
], Blocks.prototype, "containerSize", void 0);
__decorate([
    Prop({ type: Array, required: true })
], Blocks.prototype, "itemsRaw", void 0);
Blocks = __decorate([
    Component({
        components: {},
    })
], Blocks);
export default Blocks;
