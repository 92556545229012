import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import UserModule from "@/store/module/user/UserModule";
import EventDialog from "@/components/shared/dialogs/Event.vue";
let EventsHeaderActions = class EventsHeaderActions extends Vue {
    constructor() {
        super(...arguments);
        this.addEvent = {
            dialog: false,
        };
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get isCoach() {
        return this.$store.getters[UserModule.types.getters.iS_COACH];
    }
    get buttons() {
        return [
            {
                text: `Добавить событие`,
                icon: "mdi-tab-plus",
                action: () => {
                    this.addEvent.dialog = true;
                },
                css: "blue white--text",
                showOnlyForSuperAdmin: false,
                show: this.isCoach,
            },
        ];
    }
    mounted() { }
};
EventsHeaderActions = __decorate([
    Component({
        components: {
            EventDialog,
        },
    })
], EventsHeaderActions);
export default EventsHeaderActions;
